/* General Styles */
h1, h2 {
    margin: 0;
}

body {
    display: flex;
    justify-content: center;
    background: #ddd; /* 背景色 */
    width: auto; /* 寬度自動 */
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(128, 128, 128, 0.5); /* 灰色半透明 */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Inventory Header Styles */
.inventory-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #ffffff;
    text-align: center;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: center;
    padding: 10px 0; /* 更好的垂直間距 */
    z-index: 1000;
}

.red-background {
    background-color: red; /* 红色背景 */
    color: white; /* 白色字体 */
    border: none; /* 去掉边框 */
}

    .red-background:hover {
        background-color: #c61a1a; /* 鼠标悬停时的深色背景 */
        color: white; /* 鼠标悬停时的白色字体 */
    }
/* Table Styles */
.header-table {
    width: 100%; /* 表格寬度佔滿容器 */
    margin: 0;
    border: none; /* 明確設置無邊框 */
    box-shadow: none;
}
    .td.header-table {
        text-align: left;
        border: none; /* 明確設置無邊框 */
        box-shadow: none;
    }
    .td.header-table.right {
        text-align: right;
        border: none; /* 明確設置無邊框 */
        box-shadow: none;
    }
#品名 {
        max-width: 336px;
        text-align: left;
    }


#校期 {
    max-width: 128px;
}
/* Button Styles */
.button {
    border-radius: 8px;
    background-color: #444;
    color: snow;
    padding: 5px 10px;
    border: none; /* 明確設置無邊框 */
    margin: 3px ;
}

/* Input Styles */
.input {
    border-radius: 5px;
    border: groove; /* 明確設置無邊框 */
    border-width: 1px;
    border-color: #666666;
    height: 20px;
    padding: 0.5px;
}
    input#year {
        width: 60px;
        text-align: right;
    }
    input[type="number"] {
        width: 40px;
        text-align: right;
    }

    input[type="date"] {
        width: 100px;
    }

    input.name {
        width: 50%; /* 設置特定類型的輸入框寬度 */
    }

    input[name="單位"] {
        width: 60px;
    }


/* Fixed Header Styles */
.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white; /* 背景設為白色 */
    z-index: 1000;
    padding: 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 提供顯示效果 */
}

/* Table Container Styles */
.table-container {
    margin-top: 120px; /* 根據固定標題的實際高度調整此值 */
    overflow-y: auto; /* 使表格內容可滾動 */
    max-height: calc(100vh - 120px); /* 確保表格可滾動區域 */
    border-radius: 10px;
}

/* Filter Bubble Styles */
.filter-bubble {
    width: 300px; /* 調整寬度 */
    display: flex;
    flex-direction: column;
    transform: translateY(100px); /* 調整動畫效果 */
    opacity: 0;
    pointer-events: none;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

    .filter-bubble.show {
        transform: translateY(0);
        opacity: 1;
        pointer-events: auto;
    }

/* Table Styles */
.in-table {
    background: #f5f5f5;
    box-shadow: inset 0 1px 0 #fff;
    width: 100%;
    margin: 20px auto; /* 自動居中，上下邊距 */
    max-height: 100%;
    overflow: auto;
    display: block;
    border-collapse: collapse;
    border: 1px solid #ccc;
    border-radius: 10px;
}

/* Table Header Styles */
.in-th {
    background: url(https://jackrugile.com/images/misc/noise-diagonal.png), linear-gradient(#777, #444);
    box-shadow: inset 0 1px 0 #999;
    color: #fff;
    font-weight: bold;
    text-shadow: 0 1px 0 #000;
    padding: 7px 10px;
    position: sticky;
    top: 0;
    background-color: #ffffff; /* 滾動時固定標題行正常顯示背景 */
    z-index: 10;
    border-radius: 0px;
}

/* Table Cell Styles */
.in-td {
    border-right: 1px solid #fff;
    border-left: 1px solid #e8e8e8;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #e8e8e8;
    padding: 5px 10px;
    position: relative;
    transition: all 300ms;
    border-radius: 3px;
    background-color: #ffffff; /* 背景色 */
}

/* Row Styles */
.tr {
    background: url(https://jackrugile.com/images/misc/noise-diagonal.png);
}
    /* Last Row Styles */
    tr:last-of-type td {
        box-shadow: inset 0 -1px 0 #fff;
    }

        tr:last-of-type td:first-child {
            box-shadow: inset 1px -1px 0 #fff;
        }

        tr:last-of-type td:last-child {
            box-shadow: inset -1px -1px 0 #fff;
        }

/* Media Query for Mobile (Hide Product Code) */
@media (max-width: 767px) {
    .modal-content {
        max-width: calc(100% - 20%);
        max-height: calc(100% - 3%);
    }
    #編號 {
        display: none; /* 移除產品編號在行動裝置上 */

    }
    #數量-固 {
        max-width: 68px;
        display: none; /* 移除產品編號在行動裝置上 */
    }
    #單位 {
        max-width: 32px;
        display: none; /* 移除產品編號在行動裝置上 */
    }
    #product-code {
        display: none; /* 移除產品編號在行動裝置上 */
    }

    .container {
        width: 100%; /* 使用設備的完整寬度 */
        padding: 10px;
    }
    .header-container {
        width: 100%; /* 寬度佔滿 */
        padding: 8px; /* 左右內邊距 */
        margin: 0 auto; /* 使容器居中 */

    }

}



@media (min-width: 1024px) {

    .modal-content {
        max-width: calc(100% - 20%);
        max-height: calc(100% - 20%);
    }
    .header-container {
        max-width: 720px; /* 最大寬度設為720px */
        width: 100%; /* 寬度佔滿 */
        padding: 0 5px; /* 左右內邊距 */
        margin: 0 auto; /* 使容器居中 */
    }
    
    #butter-code {
        display: none; 
    }
}
.li{

   list-style: none

}
input[type=file] {
    box-sizing: border-box;
    margin: 10px 0;
    width: 100%;
    height: 100%;
    padding: 1.5px 0 0 2px;
    font-weight: 500;
    border: 2px solid #e8e8e8;
    appearance: none;
    border-radius: 3px;
}


.custom-row {
    height: 30px; /* 設定列的高度 */
}

    .custom-row td {
        padding: 5px; /* 設定單元格的內邊距 */
    }
