.fixed-calculator {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #e0e0e0;
    border-top: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;
}

.calculator {
    max-width: calc(100% - 50%);
    margin: 0 auto;
    padding: 10px;
}

    .calculator input {
        
        border: none; 
        width: 96px; 
        text-align: right; 
        padding: 10px
}

