.calc-container {
    margin: 15px auto;
    height: 43vh; /* 根据需要调整高度 */
    width: 35vh;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 设置 4 列 */
    grid-template-rows: repeat(4, 1fr); /* 设置 5 行 */
}

.calc-input {
    border: none;
    width: 70%;
    text-align: right;
    padding: 10px;
}

.digit {
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    padding: 10px;
    border-radius: 5px;
    font-size: larger;
    box-shadow: #0000003d 0 4px 4px;
    background-color: #fafafa; /* 默认浅色背景 */
    border: none;
    margin: 4px;
    width: 52px;
    height: 40px;
}

    /* 设置第一行所有按钮的深色背景 */
    .digit.execute { /* 选择前 4 个按钮 (第一行) */
        background-color: #d68f75; /* 深色背景 */
        color: white; /* 白色文字 */
    }

        .digit.execute:hover { /* 第一行的 hover 背景 */
            background-color: #a35c42; /* 深色 hover */
            color: white; /* 白色文字 */
        }

    /* 设置每行的最后一个按钮的深色背景（由右数） */
    .digit:nth-child(4n) { /* 每行的最后一个按钮 */
        background-color: #d68f75; /* 深色背景 */
    }

    /* 鼠标悬停时的样式 */
    .digit:hover {
        background-color: #e0e0e0; /* 淡色 hover */
        transition: all 100ms ease-in-out;
    }

    /* 最后一个按钮的 hover 动态效果 */
    .digit:nth-child(4n):hover {
        background-color: #a35c42; /* 深色 hover */
    }

    /* 为相应的 active 状态添加样式 */
    .digit:active {
        background-color: #858383; /* 默认 active 背景 */
    }

    .digit:nth-child(-n+4):active {
        background-color: #d68f75; /* 第一行 active 背景 */
    }

    .digit:nth-child(4n):active {
        background-color: #793a23; /* 每行最后一个按钮的 active 背景颜色 */
    }
