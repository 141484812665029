/* InventoryItemsManager.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%; /* 或自定義的寬度 */
    max-width: 500px; /* 適合行動裝置 */
}

.mobile-view {
    display: flex;
    flex-direction: column;
}
